/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { NavLink } from "react-router-dom";
const Sidebar = () => {
	return (
		<div>
			<div className="side-navbar active-nav pt-3" id="sidebar">
				<div className="sidebar-logo mb-56 nav flex-column">
					<a className="nav-link text-white p-3">
						<img src="../image/logo-white-horizontal.svg" className="unset-width-height" alt="portal" />
					</a>
				</div>
				<ul className="nav flex-column">
					<li className="nav-item">
						<NavLink className="nav-link text-white p-3" to="/campaign">
							<img src="../image/campaign-icon.svg" width="20" height="20" className="sidebar-menu-logo unset-width-height" alt="portal" />
					Campaigns
				</NavLink>
					</li>
					<li className="nav-item">
						<NavLink className="nav-link text-white p-3" to="/faqs">
							<img src="../image/FAQs.svg" width="20" height="20" className="sidebar-menu-logo unset-width-height" alt="portal" />
							FAQs
				</NavLink>
					</li>
					<li className="nav-item">
						<NavLink className="nav-link text-white p-3" to="/history">
							<img src="../image/history.svg" width="20" height="20" className="sidebar-menu-logo unset-width-height" alt="portal" />
							History
				</NavLink>
					</li>
					<li className="nav-item">
						<NavLink className="nav-link text-white p-3" to="/price">
							<img src="../image/pricing-icon.svg" width="20" height="20" className="sidebar-menu-logo unset-width-height" alt="portal" />
							Pricing
				</NavLink>
					</li>
				</ul>
				<div className="campaign-copyright sidebar-copyright">
					<p className="text-white p-4 p-md-1 text-ind-copy-right ">
						&copy; 2021 Campaign Wizard. <br />All Rights Reserved. &nbsp;<a href="https://www.campaignwizard.io/privacy-policy" className="text-white text-decoration-underline"> Privacy Policy</a>
					</p>
				</div>
			</div>
		</div>
	)
}

export default Sidebar
