import React,{useEffect} from 'react'
import { hideLoader } from '../_helpers';

const Canceled = () => {
    useEffect(() => {
		hideLoader();
	}, [])
    return (
        <div class="container">
        <div class="row">
           <div class="col-md-6 mx-auto mt-5">
              <div class="payment">
                 <div class="payment_header">
                    <div class="check"><i class="fa fa-check" aria-hidden="true"></i></div>
                 </div>
                 <div class="content">
                    <h1>Payment Decline !</h1>
                    <a href="/selected" className="mb-30px">Go to Checkout</a>
                 </div>
                 
              </div>
           </div>
        </div>
     </div>
    )
}

export default Canceled
