/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { NavLink } from "react-router-dom";
import { history, hideLoader } from '../_helpers';
import { toast } from 'react-toastify';
import portalImage from '../image/portal.jpg';

const Portal = (props) => {
	var initialValues = {
		hub_id: 'Loading...'
	}
	const [tokenInfo, setHubId] = useState(initialValues);
	useEffect(() => {
		let token = localStorage.getItem("token");
		const params = new URLSearchParams(window.location.search);
		token = params.get('t');
		localStorage.setItem("token", token);

		if (token == null || token === undefined || token === "null") {
			history.push('/');
		} else {
			hideLoader();
			getTokenInfo(token);
		}
	}, []);

	function getTokenInfo(token) {
		let headers = new Headers();
		headers.append('Content-Type', 'application/json');
		headers.append('Accept', 'application/json');
		headers.append('Access-Control-Allow-Origin', window.location.origin);
		headers.append('Access-Control-Allow-Credentials', 'true');
		fetch(window.location.origin + `/hubspot/userinfo`, {
			method: 'post',
			headers: headers,
			body: JSON.stringify({ token: token }),
		})
			.then(res => res.json())
			.then(res => {
				if (res.statusCode !== undefined && res.statusCode !== 200) {
					toast.error(JSON.parse(res.error).message);
					history.push('/error');
				} else {
					setHubId(res);
					sessionStorage.setItem('tokenInfo', JSON.stringify(res));
				}
			})
	}

	return (
		<div>
			<header className="container-fluid rm-t-r">
				<div className="row">
					<div className="col">
						<div className="campaign-wizard-top-icon py-2 ml-5">
							<svg width="32px" height="33px" viewBox="0 0 32 33" fill="currentColor" className="bi bi-file-word-fill text-white">
								<title>mark-white</title>
								<g id="Hi-Fi-Design---Interactive" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
									<g id="App-Intro-V1" transform="translate(-48.000000, -8.000000)" fill="#FFFFFF">
										<g id="mark-white" transform="translate(48.000000, 8.000000)">
											<path d="M3.46004436,12.3138103 L3.46004436,25.4101172 C3.46004436,27.7390035 5.40172694,29.6335758 7.78829847,29.6335758 L7.78829847,29.6335758 L21.19693,29.6335758 C20.9894735,31.5260078 19.3491968,33 17.3550561,33 L17.3550561,33 L3.86508706,33 C1.73038774,33 7.10542736e-15,31.3112587 7.10542736e-15,29.228335 L7.10542736e-15,29.228335 L7.10542736e-15,16.0646069 C7.10542736e-15,14.1150988 1.51580285,12.5116149 3.46004436,12.3138103 L3.46004436,12.3138103 Z M29.8653007,-5.62071119e-14 C31.0442381,-5.62071119e-14 32,0.932660382 32,2.08310205 L32,2.08310205 L32,18.6000555 C32,19.0250946 31.871505,19.4406805 31.6304168,19.7947312 L31.6304168,19.7947312 L26.5090759,26.5743106 C26.0863035,27.1511367 25.4041648,27.4932371 24.6774275,27.4932371 L24.6774275,27.4932371 L7.78809742,27.4932371 C6.60915996,27.4932371 5.6533981,26.560755 5.6533981,25.4103134 L5.6533981,25.4103134 L5.6533981,8.89318161 C5.6533981,8.46832083 5.78189314,8.05255656 6.02298128,7.69850591 L6.02298128,7.69850591 L11.1443222,0.918926427 C11.5670946,0.342100328 12.2492333,-5.62071119e-14 12.9759706,-5.62071119e-14 L12.9759706,-5.62071119e-14 Z M16.1126753,3.19894128 L13.5617837,3.19894128 L15.923497,18.6799621 C16.0048346,19.2139811 16.4747645,19.6092336 17.0280426,19.6092336 L17.0280426,19.6092336 L17.1521161,19.6025799 C17.6403146,19.549788 18.0425193,19.1881184 18.128567,18.7065382 L18.128567,18.7065382 L19.3109773,12.0835615 C19.4818775,11.1252168 20.8916674,11.1252168 21.0629332,12.0835615 L21.0629332,12.0835615 L22.2453435,18.7065382 C22.3385618,19.2282501 22.8026428,19.6092336 23.3455024,19.6092336 C23.8985977,19.6092336 24.3685276,19.2139811 24.450048,18.6799621 L24.450048,18.6799621 L26.8121268,3.19894128 L24.2610524,3.19894128 L23.1036831,8.51933266 L23.0567084,8.51933266 L21.0960166,3.19894128 L19.2537669,3.19894128 L17.3643596,8.26570041 L17.3172021,8.26570041 L16.1126753,3.19894128 Z" id="Combined-Shape"></path>
										</g>
									</g>
								</g>
							</svg>
						</div>
					</div>
					<div className="col d-flex align-items-center justify-content-end">
						<div>
							<a className="top-header-text text-decoration-none">HubSpot Portal ID: {tokenInfo.hub_id}</a>
						</div>
					</div>
				</div>
			</header>
			<div className="py-5">
				<div className="container">
					<div className="row ">
						<div className="col-lg-6 col-12 mt-95">
							<h2 className="portal-color">Your portal is connected! </h2>
							<h4 className="right-content-inde-3 py-2">Campaign Wizard offers turnkey campaign templates for HubSpot users. </h4>
							<p className="text-right-color text-ind-3-color">Looking to launch sales and marketing efforts faster, with no QA/QC headaches and at a fraction of the cost of an agency? Campaign Wizard is the optimal solution for:
</p>
							<div className="text-right-color">
								<p>
									<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check-circle pe-2 icon-size" viewBox="0 0 16 16">
										<path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
										<path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
									</svg>
									<span>New HubSpot users looking to rapidly deploy initiatives </span>
								</p>
								<p>
									<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check-circle pe-2 icon-size" viewBox="0 0 16 16">
										<path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
										<path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 	11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
									</svg>
									<span>Seasoned HubSpot users looking to spend more time on strategy</span>
								</p>
								<p>
									<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check-circle pe-2 icon-size" viewBox="0 0 16 16">
										<path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
										<path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
									</svg>
									<span>Small teams that are budget-conscious but need results</span>
								</p>
								<p>
									<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check-circle pe-2 icon-size" viewBox="0 0 16 16">
										<path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
										<path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 	1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
									</svg>
									<span>Agile teams that launch multiple initiatives (these campaigns are easily cloneable)</span>
								</p>
								<p>
									<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check-circle pe-2 icon-size" viewBox="0 0 16 16">
										<path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
										<path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 	1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
									</svg>
									<span>Drastically reduce the amount of QA; templates are already vetted</span>
								</p>
								<p>
									<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check-circle pe-2 icon-size" viewBox="0 0 16 16">
										<path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
										<path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 	1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
									</svg>
									<span>HubSpot users feeling scared to press the ON button (it happens to the best of us!)</span>
								</p>
							</div>
							<div className="pt-3 "><NavLink to="/campaign"><button type="button" className="try-agin-btn">Get Started</button></NavLink></div>
						</div>
						<div className="col-lg-6 col-12 mt-95">
							<div>
								<img src={portalImage} className="portal-image" alt="portal" />
							</div>
						</div>
					</div>
					<footer className="container-footer">
						<div className="row g-0 footer-position">
							<div className="col-12">
								<div className="text-center text-ind-copy-right">
									<p>&copy; 2021 Campaign Wizard. All Rights Reserved. &nbsp;
										<a href="https://www.campaignwizard.io/privacy-policy" className="text-decoration-underline text-secondary">Privacy Policy.</a>
									</p>
								</div>
							</div>
						</div>
					</footer>
				</div>
			</div>
		</div>
	)
}

export default Portal