import { userConstants } from '../_constants';

export function users(state = {}, action) {
    switch (action.type) {
        case userConstants.CART_ITEM_SUCCESS:
            return {
                cartInfo: action.cartInfo
            };
        case userConstants.FETCH_CART_ITEM_SUCCESS:
            return {
                fetchPreviousCart: action.fetchCartInfo
            };

        default:
            return state
    }
}