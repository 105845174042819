/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import Sidebar from "./Sidebar";
import Header from "./Header";
import { hideLoader } from '../_helpers';
import { Modal, Accordion, Card } from "react-bootstrap";
import faqsArr from "../faqs.json";

const Faqs = () => {

	const [show, setShow] = useState(false);
	const [activeIndex, setActiveIndex] = useState(0);
	const handleClose = () => setShow(false);
	const handleOpen = () => {
		setShow(true);
	}

	useEffect(() => {
		hideLoader();
	}, [])

	useEffect(() => {
		const close = (e) => {
			if (e.keyCode === 27) {
				handleClose();
			}
		}
		window.addEventListener('keydown', close)
		return () => window.removeEventListener('keydown', close)
	}, [])

	function activateTab(index) {
		setActiveIndex(activeIndex === index ? 0 : index);
	}

	return (
		<div>
			<Sidebar />
			<div className="compaign-right-content-main p-3">
				<Header />
				<section className="campaign-wizard-step-main p-3 mt-3">
				<div className="container">
					<div className="row px-xl-5">
						<div className="col-xl-6 col-12 pt-3">
							<div className="pt-3">
								<h3 className="pb-2 ind-4-sec-page-title">Find the answers to your questions here.</h3>
							</div>
							<p className="ind-5-text-left">
								If you don't see the answers you are looking for and or need assistance, please feel free to email us at <a href="mailto:support@campaignwizard.io">support@campaignwizard.io</a>
							</p>
						</div>
						<div className="col-xl-2 col-12 pt-3 p-0">

							<div className="faqimg">
								<div className="faqimg1">
									<a className="text-decoration-underline" onClick={() => handleOpen()}>
										<svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" className="bi bi-caret-right-fill faqicon" viewBox="0 0 16 16">
											<path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
										</svg>
									</a>
								</div>
							</div>
						</div>
						<div className="col-xl-4 col-12">
							<div className="psize">
								<p className="ind-4-sec-page-content pt-5">
									Getting started with Compaign Wizard in less than 3 minutes.
					</p>
							</div>
						</div>
					</div>
					
					
					<div className="row px-xl-5 pt-3">
						<div className="col faq-sec-width">

							<div className="accordion accordion-flush" id="accordionFlushExample">
								<Accordion defaultActiveKey="0">
									{
										faqsArr.map(function (faq, index) {
											return (
												<div className="accordion-item" key={index} onClick={() => activateTab(index + 1)}>
													<h2 className="accordion-header">
														<Accordion.Toggle as={Card.Header} eventKey={index + 1} className="accordion-button collapsed fw-bold text-start" type="button">
															<div className="d-flex">
																<span className="z-index mr-3">
																	{activeIndex === index + 1 ?
																		<img src="../image/faq-arrow-close.svg" className="faq-arrow" alt="arrow" />
																		: <img src="../image/faq-arrow-open.svg" className="faq-arrow" alt="arrow" />
																	}
																</span>{faq.Q}
															</div>
														</Accordion.Toggle>
													</h2>
													<Accordion.Collapse eventKey={index + 1}>
														<div className="accordion-body">
															{faq.A}
														</div>
													</Accordion.Collapse>
												</div>
											)
										})
									}
								</Accordion>
							</div>
						</div>
					</div>
					
					<Modal show={show} centered className="modal-dialog-image bg-transparent modal-bg-transparent">
						<Modal.Header className="bg-transparent border-0">
							<div className="text-end modal-button">
								<button type="button" className="btn-close btn-close-white-light p-3 text-white ind-4-sec-page-close" onClick={() => handleClose()} aria-label="Close"></button>
							</div>
						</Modal.Header>
						<Modal.Body className="p-0">
							<div className="row">
								<div className="embed-responsive embed-responsive-16by9">
									<iframe width="320" height="240" src="https://www.youtube.com/embed/_xlot3NBDfo" title="Campaign Wizard"
										frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
										allowFullScreen></iframe>
								</div>
							</div>
						</Modal.Body>
					</Modal>
					</div>
				</section>
			</div>
		</div>
	)
}

export default Faqs
