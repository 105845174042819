/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { history } from '../_helpers';

const Header = () => {

	const [hubId, setHubId] = useState('');
	useEffect(() => {
		let tokenInfo = sessionStorage.getItem("tokenInfo");
		if (!tokenInfo) {
			history.push('/');
		}
		setHubId(JSON.parse(tokenInfo).hub_id);
	}, []);

	return (
		<div>

			<header className="py-2">
				<ul className="nav justify-content-end">
					<li className="nav-item dropdown">
						<span className="nav-link text-white cstm-nav-link">HubSpot Portal ID:  {hubId}</span>
						{/* <ul className="dropdown-menu">
							<li><a className="dropdown-item" href="#">Action</a></li>
							<li><a className="dropdown-item" href="#">Another action</a></li>
							<li><a className="dropdown-item" href="#">Something else here</a></li>
						</ul> */}
					</li>
				</ul>
			</header>

		</div>
	)
}

export default Header
