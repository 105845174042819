import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { get } from 'lodash';
import { toast } from "react-toastify";
import { getUserInfo, history, hideLoader, showLoader } from "../_helpers";

const Success = () => {
  const search = useLocation().search;
  let index = new URLSearchParams(search).get("index");
  const [patmentDetails, updatePaymentDetails] = useState({});
  const [ctId, updateCTId] = useState(0);
  const [failedPackagesName, updatefailedPackagesName] = useState(null);
  const [showResponseModal, setResponseShow] = useState(false);
  const handleResponseClose = () => setResponseShow(false);

  function bindHeaders() {
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Accept", "application/json");
    headers.append("Access-Control-Allow-Origin", window.location.origin);
    headers.append("Access-Control-Allow-Credentials", "true");
    return headers;
  }

  useEffect(() => {
    
    const queryString = get(history, 'location.search', null);
    const campaigns = get(history, 'location.state.campaigns.title', null);

    if(queryString && campaigns) {
      index = 'noindex';
    }

    let headers = bindHeaders();
    fetch(window.location.origin  + `/getSession?index=${index}&campaigns=${campaigns}`)
      .then((res) => res.json())
      .then((res) => {
        updatePaymentDetails(res);
        var userInfo = getUserInfo();
        let transactionNoPayment = {};
        let transactionsReqModel = {
          hub_id: userInfo.hub_id,
          user: userInfo.user,
          title: res.title,
        };
        if(queryString) {
          transactionNoPayment = {
            amount: 0.1,
            id: 'nopayment'
          }
        } else {
          transactionsReqModel = {
            id: res.payment_intent,
            amount: res.amount_total / 100,
          };
        }
        transactionsReqModel = {
          ...transactionsReqModel,
          ...transactionNoPayment
        }
        var requestOptions = {
          method: "POST",
          headers: headers,
          body: JSON.stringify(transactionsReqModel),
        };

        fetch(
          window.location.origin  + "/Customers/transactions",
          requestOptions
        )
          .then((response) => response.json())
          .then((result) => {
            
            hideLoader();
            var response = JSON.parse(result);
            if (response.error === 0) {
              updateCTId(response.id);
            } else {
              toast.error(response.message);
            }
          })
          .catch((error) => {
            hideLoader();
            toast.error("error", error);
          });
      });

    hideLoader();
  },[]);

  async function startTransfer() {
    
    showLoader();
    let headers = bindHeaders();
    var userInfo = getUserInfo();
    var reqModel = {
      token: userInfo.token,
      packages: patmentDetails.title,
      ctId: ctId,
    };
    var requestOptions = {
      method: "POST",
      headers: headers,
      body: JSON.stringify(reqModel),
    };

    await fetch(
      window.location.origin  + "/Customers/transfer",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        
        hideLoader();
        var resultArr = result.filter((x) => x.isCloned === false);
        if (resultArr.length > 0) {
          updatefailedPackagesName(
            resultArr
              .filter((x) => x.isCloned === false)
              .map((x) => x.package)
              .toString()
          );
          setResponseShow(true);
        } else {
          toast.success("Campaign transferred successfully.");
          sessionStorage.removeItem('historyCampaigns');
          history.push("/history");
        }
      })
      .catch((error) => {
        hideLoader();
        toast.error("An error has occured, Please contact system admin", error);
      });
  }

  return (
    <div className="container-fuild">
      <div class="container">
        <div class="row">
          <div class="col-md-6 mx-auto mt-5">
            <div class="payment">
              <div class="payment_header">
                <div class="check">
                  <i class="fa fa-check check-icon" aria-hidden="true">
                    <svg id="bold" enable-background="new 0 0 24 24" height="40" viewBox="0 0 24 24" width="50" xmlns="http://www.w3.org/2000/svg"><g><path d="m9.707 19.121c-.187.188-.442.293-.707.293s-.52-.105-.707-.293l-5.646-5.647c-.586-.586-.586-1.536 0-2.121l.707-.707c.586-.586 1.535-.586 2.121 0l3.525 3.525 9.525-9.525c.586-.586 1.536-.586 2.121 0l.707.707c.586.586.586 1.536 0 2.121z"/></g></svg>
                  </i>
                </div>
              </div>
              <div class="content">
                <h1>Payment Success !</h1>
                <p className="p-1">Thank you for your purchase. Click the button below to
                    transfer your portal. </p>
                    <button
                      className="ind-4-sec-page-button mb-30px"
                      onClick={() => startTransfer()}
                      role="button"
                    >
                      Transfer Campaigns
                    </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {
        <Modal
          show={showResponseModal}
          onHide={handleResponseClose}
          backdrop="static"
        >
          <Modal.Body>
            <div className="text-end px-3 pt-2">
              <button
                type="button"
                className="btn-close ind-4-sec-page-close"
                onClick={() => history.push("/history")}
                aria-label="Close"
              ></button>
            </div>
            <div className="row px-4">
              <div className="col  text-center">
                <h2 className="modal1-title">Unable to migrate!</h2>
                <p className="p-3 modal2-contant text-center">
                  Unable to migrate "<b>{failedPackagesName}</b>" packages.
                  Please contact support.
                </p>
                <div>
                  <a
                    className="btn btn-primary text-center transfer-btn ind-4-sec-page-button"
                    href="mailto:support@campaignwizard.io"
                    role="button"
                  >
                    Contact Support
                  </a>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      }
    </div>
  );
};

export default Success;
