/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import Sidebar from "./Sidebar";
import Header from "./Header";
import { toast } from "react-toastify";
import { history, hideLoader } from "../_helpers";
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "../_actions";
import campaignsArr from "../campaignList.json";
import { Modal } from "react-bootstrap";
import bannerAd2 from '../image/Banner-ad-2.jpg';

const Campaign = () => {
  const dispatch = useDispatch();
  const { fetchPreviousCart } = useSelector((state) => state.users);
  const [cartItems, updateCartItems] = useState([]);
  const [packageDetails, updatePackageDetails] = useState(null);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  function cartClickEvent(campaign, checked) {
    //debugger;
    if (checked) {
      updateCartItems((arr) => [...arr, campaign]);
    } else {
      var array = [...cartItems];
      array = array.filter((item) => item.Id !== campaign.Id);
      updateCartItems(array);
    }
  }

  useEffect(() => {
    hideLoader();
    if (fetchPreviousCart) {
      updateCartItems(fetchPreviousCart);
    }
    dispatch(userActions.updateCart(cartItems));
  }, [cartItems, dispatch, fetchPreviousCart]);

  async function handleNextEvent() {
    if (cartItems.length === 0) {
      toast.error("Please select at least one compaign");
    } else {
      history.push("/selected");
    }
  }

  function displayDetails(title) {
    updatePackageDetails(campaignsArr.filter((x) => x.Title === title)[0]);
    setShow(true);
  }

  return (
    <div>
      <Sidebar />
      <div className="compaign-right-content-main p-3">
        <Header />

        <section className="campaign-wizard-step-main compaign-list-box-next mt-26 rm-p-t">
          <div className="container-fluid">
            <div className="container">
              <div className="row campaign-wizard-wrap-row py-4">
                <img
                  src={bannerAd2}
                  className="portal-image"
                  alt="portal"
                />
              </div>
            
           
              <div className="row pb-4 align-items-center">
                <div className="col-12 col-sm-5">
                  <p className="text-14 mb-0 c-instructions-color">
                    Please select all campaigns you are interested in.
                  </p>
                </div>
                <div className="col-12 col-sm-7">
                  <div
                    className={
                      cartItems.length > 0
                        ? "text-end pt-2 ind-4-total"
                        : "text-end pt-2 custom-text-disabled ind-4-total"
                    }
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="currentColor"
                      className="bi bi-cart3"
                      viewBox="0 0 16 16"
                    >
                      <path d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .49.598l-1 5a.5.5 0 0 1-.465.401l-9.397.472L4.415 11H13a.5.5 0 0 1 0 1H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM3.102 4l.84 4.479 9.144-.459L13.89 4H3.102zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                    </svg>
                    <span
                      className={
                        cartItems.length > 0
                          ? "ind-4-total-count"
                          : "custom-text-disabled ind-4-total-count"
                      }
                    >
                      &nbsp;{cartItems.length}
                    </span>{" "}
                    &nbsp;&nbsp;&nbsp;|
                    <span className="ps-3">
                      Your total: $
                      {cartItems
                        .map((x) => (x.isFree ? 0 : x.Price))
                        .reduce((acc, cur) => acc + cur, 0)
                        .toFixed(2)}
                    </span>
                    <button
                      type="button"
                      onClick={() => handleNextEvent()}
                      className={
                        cartItems.length > 0
                          ? "next"
                          : "custom-button-disabled next"
                      }
                    >
                      Next &nbsp;&nbsp;
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-arrow-right"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fillRule="evenodd"
                          d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
           
            
              <div className="row">
                {campaignsArr.map(function (campaign, index) {
                  return (
                    <div
                      className="col-lg-3 col-sm-6 col-12 d-flex flex-row padd-box"
                      key={campaign.Id}
                    >
                      <div className="form-check btn-color  float-end">
                        <input
                          className="form-check-input form-check-input1 me-2"
                          type="checkbox"
                          defaultChecked={
                            fetchPreviousCart == null ? false : fetchPreviousCart.filter(
                              (x) => x.Id === campaign.Id
                            ).length > 0
                          }
                          onChange={(e) =>
                            cartClickEvent(campaign, e.target.checked)
                          }
                          id={"flexCheckDefault_" + campaign.Id}
                        />
                        <label
                          className="checkmark clearfix pb-3 w-100"
                          htmlFor={"flexCheckDefault_" + campaign.Id}
                        >
                          <div className="row align-items-center">
                            <div className="col-5 col-sm-5 col-md-5">
                              <span
                                key={index}
                                dangerouslySetInnerHTML={{
                                  __html: campaign.Icon,
                                }}
                              ></span>
                            </div>
                            <div className="col-6 col-sm-6 col-md-6 cam-div cam-div-price">
                              { campaign.isFree ? <h3 className="price-free">Free</h3> : <h3 className="price">${campaign.Price}</h3> } 
                            </div>
                          </div>

                          <h3 className="float-start ind-4-box-title text-start w-100 mt-2">
                            {campaign.Title}
                          </h3>
                          <a
                            className="float-end see-details"
                            onClick={() => displayDetails(campaign.Title)}
                          >
                            See Details
                          </a>
                        </label>
                      </div>
                    </div>
                  );
                })}
              </div>
              </div>
          </div>
          <div>
            {/*<!-- Modal See Details dialog -->*/}
            <Modal show={show} className="modal-dialog-image">
              <Modal.Body>
                <div className="row">
                  <div className="text-end modal-button">
                    <button
                      type="button"
                      className="btn-close text-dark p-3  ind-4-sec-page-close"
                      onClick={() => handleClose()}
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="col-lg-6 col-12">
                    <div className="campaign-see-details-modal">
                      <span
                        dangerouslySetInnerHTML={{
                          __html: packageDetails == null ? "" : (packageDetails.Details == null ? "" : packageDetails.Details.ImagePath),
                        }}
                      ></span>
                    </div>
                  </div>
                  <div className="col-lg-6 col-12 ps-4">
                    <div className="pe-3 pt-3">
                      <h3 className="modal-see-title">
                        {
                          packageDetails == null ? 0: (packageDetails.isFree ? "Free" : `$${packageDetails.Price}`)
                        }
                      </h3>
                    </div>
                    <div className="pe-2 pt-2">
                      <h3 className="modal-see-title">
                        {packageDetails == null ? "" : packageDetails.Title}
                      </h3>
                      <p className="modal2-contant">
                        {packageDetails == null ? "" : (packageDetails.Details == null ? "" : packageDetails.Details.Description)}
                      </p>
                    </div>
                    <div className="pe-4">
                      <ul className="model-list">
                        <li>
                          <div className="row ps-2">
                            <div className="col-1 pt-1 gx-1 d-flex">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-check-circle pe-2 icon-size"
                                viewBox="0 0 16 16"
                              >
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                              </svg>
                            </div>
                            <div className="col-10 gx-2">
                              <p className="modal2-contant">
                              {packageDetails == null ? "" : (packageDetails.Details == null ? "" : packageDetails.Details.AssetsIncluded)}
                              </p>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="row ps-2">
                            <div className="col-1 pt-1 gx-1 d-flex">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-check-circle pe-2 icon-size"
                                viewBox="0 0 16 16"
                              >
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                              </svg>
                            </div>
                            <div className="col-10 gx-2">
                              <p className="modal2-contant">
                                Software Requirements:{" "}
                                {packageDetails == null ? "" : (packageDetails.Details == null ? "" : packageDetails.Details.SoftwareRequirements)}
                              </p>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="row ps-2">
                            <div className="col-1 pt-1 gx-1 d-flex">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-check-circle pe-2 icon-size"
                                viewBox="0 0 16 16"
                              >
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                              </svg>
                            </div>
                            <div className="col-10 gx-2">
                              <p className="modal2-contant">
                                Delivery Time:{" "}
                                {packageDetails == null ? "" : (packageDetails.Details == null ? "" : packageDetails.Details.DeliveryTime)}
                              </p>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </Modal.Body>
            </Modal>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Campaign;
